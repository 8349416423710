import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import PageLogin from "./pages/PageLogin";
import PageAtivarAcesso from "./pages/PageLoginAtivar";
import PageForgotPass from "./pages/PageLoginForgot";
import PageDetalhamento from "./pages/PageDetalhamento";
import PagePerspecGlobal from "./pages/PagePerspecGlobal";
import PageRelatorios from "./pages/Relatorios";
import PageCadastroUser from "./pages/CadastroUser";
import "./scss/global.scss"
import PageConfigHorariosGrade from "./pages/ConfigGradeHorarios";
import PageRelatoriosBySetor from "./pages/Relatorios/RelatoriosBySetor";


const router = createBrowserRouter([
  {
    path: "/",
    element: <PageLogin />,
  },
  {
    path: "/Ativar-acesso",
    element: <PageAtivarAcesso />,
  },
  {
    path: "/Forgot-pass",
    element: <PageForgotPass />,
  },
  {
    path: "/Detalhamento",
    element: <PageDetalhamento />,
  },
  {
    path: "/Canais",
    element: <PagePerspecGlobal />,
  },
  {
    path: "/configuracoes",
    element: <PageConfigHorariosGrade />,
  },
  {
    path: "/cadastrar-usuarios",
    element: <PageCadastroUser />,
  },
  {
    path: "/relatoriosTeste",
    element: <PageRelatorios />
  },
  {
    /*path: "/pontos-de-monitoria/relatorios",*/
    path: "/relatorios",
    element: <PageRelatoriosBySetor />,
  }
]);

const App = () => <RouterProvider router={router} />;

export default App;
